import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const query = graphql`
  {
    allContentfulBanner(filter: { landscapeBanner: { eq: true } }) {
      nodes {
        link
        banner {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        title
      }
    }
  }
`;

const aniSmooth = "transition-all duration-[0.3s] ease-in-out";

const Banner = () => {
  const {
    allContentfulBanner: { nodes: banners },
  } = useStaticQuery(query);
  return (
    <div
      className={`${aniSmooth} hover:brightness-110 w-full 2xl:w-[1440px] h-full flex flex-col px-4 md:px-14`}
    >
      <div className="flex justify-start items-center w-full">
        <div className="w-[7px] h-[35px] bg-green-120 opacity-80"></div>
        <div className="font-cardo font-bold text-3xl text-stone-900 pl-4 cursor-default flex justify-center items-center">
          Our Sponsor
          <div className="flex justify-center items-center">
            <div className="h-[2px] w-[20px] bg-stone-200 ml-3"></div>
            <div className="bg-stone-400 font-jarkatar text-xs text-white px-2 py-0.5 rounded ml-3">
              Ad
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        {banners.map((item, index) => {
          const { banner, link, title } = item;
          const pathToImage = getImage(banner);

          return (
            <a href={link} key={index} className="w-full h-full object-fill">
              <GatsbyImage
                image={pathToImage}
                alt={title}
                className={`object-fill h-auto w-full group-hover:brightness-[100%] brightness-[90%]`}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Banner;
