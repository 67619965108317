import * as React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ButtonUp from "../utilities/ButtomUp";
import { AiFillPhone } from "@react-icons/all-files/ai/AiFillPhone";
import { IoShareSocialSharp } from "@react-icons/all-files/io5/IoShareSocialSharp";
import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp";
import Seo from "../components/Seo";
import Banner from "../components/Banner";

const smooth = "transition-all ease-in-out duration-300";

const Contact = () => {
  return (
    <div className="relative overflow-hidden bg-stone-50">
      <Seo title="Contact" pathname="/contact/" />
      <div id="top" />
      <Navbar className="relative z-10 " />
      <div className="relative z-20 flex flex-col justify-center items-center">
        <div className="w-full 2xl:w-[1440px] px-4 md:px-14 h-full">
          <div className=" flex flex-col justify-center items-center">
            <div className="w-full flex justify-start font-jarkatar font-extrabold text-4xl">
              Let's Connect
            </div>

            <div className="mt-6 font-jarkatar font-normal text-base lg:text-lg text-justify w-full">
              We love to hear from you. Whether you are curious about our
              articles, organization, or even just want ot talk with us - we are
              open to answer all enquiries.
            </div>

            <div className="lg:w-full mt-10 lg:mt-14">
              <div className="w-full flex flex-col lg:flex-row">
                {/* Tel */}
                <div className="w-full lg:w-1/3 flex justify-start lg:justify-center items-start my-4 lg:my-0">
                  <div className="h-full flex justify-center items-start mr-4">
                    <div className="w-16 h-16 lg:w-20 lg:h-20 bg-white rounded-full drop-shadow-lg shadow-lg grid place-content-center">
                      <AiFillPhone className="text-green-120 text-3xl lg:text-4xl" />
                    </div>
                  </div>

                  <div className=" h-full flex flex-col">
                    <div className="border-b-[2px] border-stone-300 pb-3 mb-3 font-jarkatar font-medium text-xl">
                      Phone Number
                    </div>
                    <a
                      href="tel:+8562059644074"
                      className={`${smooth} hover:text-green-120 font-jarkatar font-medium my-1`}
                    >
                      +856-20-5964-4074
                    </a>
                    <a
                      href="tel:+8562028039022"
                      className={`${smooth} hover:text-green-120 font-jarkatar font-medium my-1`}
                    >
                      +856-20-2803-9022
                    </a>
                  </div>
                </div>

                {/* Email */}
                <div className="w-full lg:w-1/3 flex justify-start lg:justify-center items-start my-4 lg:my-0">
                  <div className="h-full flex justify-center items-start mr-4">
                    <div className="w-16 h-16 lg:w-20 lg:h-20 bg-white rounded-full drop-shadow-lg shadow-lg grid place-content-center">
                      <IoShareSocialSharp className="text-green-120 text-3xl lg:text-4xl" />
                    </div>
                  </div>

                  <div className=" h-full flex flex-col">
                    <div className="border-b-[2px] border-stone-300 pb-3 mb-3 font-jarkatar font-medium text-xl">
                      Online Contact
                    </div>
                    <a
                      href="mailto:econox.laos@gmail.com "
                      className={`${smooth} hover:text-green-120 font-jarkatar font-medium my-1`}
                    >
                      Email: econox.laos@gmail.com
                    </a>
                    <a
                      href="https://www.facebook.com/econewslao/"
                      className={`${smooth} hover:text-green-120 font-jarkatar font-medium my-1`}
                    >
                      Facebook: @econewslao
                    </a>
                  </div>
                </div>

                {/* Address */}
                <div className="w-full lg:w-1/3 flex justify-start lg:justify-center items-start my-4 lg:my-0">
                  <div className="h-full flex justify-center items-start mr-4">
                    <div className="w-16 h-16 lg:w-20 lg:h-20 bg-white rounded-full drop-shadow-lg shadow-lg grid place-content-center">
                      <IoLocationSharp className="text-green-120 text-3xl lg:text-4xl" />
                    </div>
                  </div>

                  <div className=" h-full flex flex-col">
                    <div className="border-b-[2px] border-stone-300 pb-3 mb-3 font-jarkatar font-medium text-xl">
                      Location
                    </div>
                    <div className="font-jarkatar font-medium my-1">
                      Ban Naxay, Saysettha District, Vientiane Capital, Lao PDR
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative w-screen flex justify-center items-center mt-24 mb-12">
              <Banner />
            </div>
          </div>
          <Footer />
        </div>

        <ButtonUp link={"contact/#top"} />
      </div>
    </div>
  );
};

export default Contact;
